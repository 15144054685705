.errorBox {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}
.errorBox .picture {
    position: relative;
    aspect-ratio: 348 / 348;
    margin: 1rem auto;
    max-width: 60%;
}

.errorBox .picture img {
    width: 100%;
    aspect-ratio: 348 / 348;
    height: 100%;
    object-fit: cover;
}

.errorBox .picture::after {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: 100% 100%;
    background-image: url('../assets/images/frame_square.png');
    z-index: 2;
    pointer-events: none;
}

.errorBox h3 {
    margin-top: 2rem;
    color: #EB480D;
    font-family: 'Goblin One', serif;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}