
.selectButton {
  width: 100%;
  background-image: url('../assets/images/beige.png');
  background-size: cover;
  text-align: center;
  border: none;
  padding: 0.6rem 2rem;
  border-radius: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
}

.selectButton.selected {
  background-image: url('../assets/images/pink.png');

}

/*
.selectButton::after {
  content: '';  
  background-color: rgba(255,255,255,0.5);
  width: 100%; height: 100%;
  top: 0; left: 0;
  position: absolute;
  transition: background-color 0.1s ease-out;
}
.selectButton.selected::after {
  background-color: rgba(255,255,255,0);
}
.selectButton.selected {
  opacity: 1;
  color: black;
}
.selectGroup:hover .selectButton.selected::after {
  background-color: rgba(255,255,255,0.5);
}

.selectGroup:hover .selectButton.selected {
  font-weight: normal;
}
.selectGroup:hover .selectButton:hover::after {
  background-color: rgba(255,255,255,0);
}
*/
