@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&family=Goblin+One&display=swap);
body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  --container-margin: 2rem;
}

a {
  color:#eb480d;
  text-decoration: none;
}

.header {
  padding: var(--container-margin);
  display: -webkit-flex;
  display: flex;
}

.header h1 {-webkit-flex: 1 1;flex: 1 1;}

@media only screen and (max-width: 700px) {
  .header {
    -webkit-flex-direction: column;
            flex-direction: column;
  }
  .header h1 {
    margin-bottom: 1rem;
  }
}

h1 {
  color: #121212;
  font-family: 'Goblin One', serif; 
  margin: 0; 
  font-size: 1.7rem;
  font-weight: normal;
}

.container {
  padding: var(--container-margin);
  background-size: cover;
}

.inputGroup {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
  grid-gap: 1rem;
  gap: 1rem;
  margin-top: 1rem;
}

h3 {
  /* color: #EB480D; */
  color: #121212;
  font-family: 'Goblin One', serif; 
  margin: 0; 
/*   font-size: 1.2rem; */
  font-weight: normal;
}

.center {
  max-width: 950px;
  margin: 0 auto;
}

.cta button {
  background-image: url(/static/media/yellow.8aef21ba.png);
}

button {
  background-image: url(/static/media/postcards_02_input.b7fb9146.png);
  background-size: cover;
  text-align: left;
  border: none;
  padding: 0.6rem 3rem;
  border-radius: 1.5rem;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
}

button {
  transition: outline 0.1s;
}

button:hover {
  border: none;
  box-shadow: 0 0 0 2pt #d65637;
  outline: none;
  transition: .1s;
}

.topbutton:hover {
  box-shadow: 0 0 0 2pt #EDD6A6;
}
.cardHeader {
    display: -webkit-flex;
    display: flex;
}

.cardHeader .picture {
    position: relative;
    aspect-ratio: 1 / 1;
    max-height: 200px;
}

.cardHeader .picture img {
    width: 100%;
    aspect-ratio: 1 / 1;
    height: 100%;
    object-fit: cover;
}

.cardHeader .picture::after {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: 100% 100%;
    background-image: url(/static/media/frame.2dcbafdc.png);
    z-index: 2;
    pointer-events: none;
}

.smallpic {
    height: 3rem;
}

.smallcard {
    display: -webkit-flex;
    display: flex;
    padding: 0.5rem;
    background: white;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.smallinfo {
    padding: 0 1rem;
    font-size: 0.8rem;
    -webkit-justify-content: center;
            justify-content: center;
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
}

.cta {
    margin-top: 1.5rem;
}

.cardHeader .content {
    -webkit-flex: 1 1;
            flex: 1 1;
    padding: 0.5rem 1.5rem;
}

.cardHeader .content span {
    margin-top: 0.5rem;
    display: block;
}

.content .smallinfo .from  {
    margin-top: 0;
}

.cardHeader .big {
   display: -webkit-flex;
   display: flex;
   -webkit-flex-direction: column;
           flex-direction: column;
   -webkit-justify-content: flex-end;
           justify-content: flex-end;
}

.cardHeader .big span {
    text-align: right;
}

.cardHeader .big span:nth-child(1) {
    font-size: 1.2rem;
}

.cardHeader .big span:nth-child(2) {
    font-weight: bold;
    font-size: 2rem;
}

.page .center:nth-child(2) {
    margin-top: 2rem;
}

.personDescription {
    color: #777;
    font-style: italic;
}
.resultGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    /* 4gaps adds up to 4rem */
    grid-auto-flow: dense;
    width: calc(100% - 4rem + 1rem);
    padding: 1.5rem !important;
}

@media only screen and (max-width: 1150px) {
    .resultGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 900px) {
    .resultGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 600px) {
    .resultGrid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 500px) {
    .resultGrid {
        grid-template-columns: 1fr 1fr;
    }
}


.cardPadding {
    padding: 0.5rem;
}

.cardType {
    text-transform: uppercase;
/*     font-size: 14px; */
    display: block;
    margin-bottom: 0 !important;
    color: rgba(0,0,0,0.7);
}

.cardLink {
    text-decoration: none;
    display: contents;
}

.peopleCard, .collectionsCard, .projectsCard {
    cursor: pointer;
    outline-offset: -0.5rem;
    outline: 3px solid transparent;
    transition: outline 0.1s;
    overflow: hidden;
}

.peopleCard:hover, .collectionsCard:hover, .projectsCard:hover {
    outline: 3px solid #EDD6A6;
    
}

.peopleCard::before, .collectionsCard::before, .projectsCard::before {
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: calc(100% - 1rem); height: calc(100% - 1rem);
    background-image: url(/static/media/old_paper6_2_1.269cb186.png); /* 2:1 */
    background-size: 100% 100%;
    z-index: -1;
    margin: 0.5rem;
}

/* bottom inner shadows for long & overflowing collection cards */
.collectionsCard::after, .projectsCard::after, .peopleCard::after {
    content: '';
    position: absolute;
    top: 0.5rem; left: 0.5rem;
    width: calc(100% - 1rem); height: calc(100% - 1rem);
    box-shadow: 0px -40px 40px #EFE6D2 inset;
    z-index: 3;
    pointer-events: none;
}

.peopleCard .content, .collectionsCard .content, .projectsCard .content {
    margin: 1.5rem 1rem 1.5rem 0.5rem;
/*     padding: 0.5rem 15px 0.5rem 0; */
    font-size: 0.8rem;
    color: #121212;

    overflow-y: auto;
    overflow-x: hidden;
    
    -webkit-flex: 1 1;
    
            flex: 1 1;

}
.collectionsCard .content, .projectsCard .content {
    margin: 0 0.6rem 0.5rem 0.7rem;
    padding: 0 0.5rem 1rem 0.5rem;
}

.peopleCard .content *, .collectionsCard .content *, .projectsCard .content * {
    margin-bottom: 0.5rem;
    display: block;
}

/* People */

.peopleCard {
    grid-column: span 2;
    aspect-ratio: 2 / 1; /* to-do: make this cross browser*/
    width: 100%;
    display: -webkit-flex;
    display: flex;
    /*padding: 0.5rem;*/
    position: relative;
}

.peopleCard .picture {
    position: relative;
    aspect-ratio: 308 / 348;
    margin: 1rem 0.5rem 1rem 1rem;
    z-index: 5;
}

.peopleCard .picture img {
    width: 100%;
    aspect-ratio: 308 / 348;
    height: 100%;
    object-fit: cover;
}

.peopleCard .picture::after {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: 100% 100%;
    background-image: url(/static/media/frame.2dcbafdc.png);
    z-index: 2;
    pointer-events: none;
}

/* Collections */

.collectionsCard {
    grid-column: span 2;
    grid-row: span 2;
    aspect-ratio: 1 / 1; /* to-do: make this cross browser*/
    width: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
    /*padding: 0.5rem;*/
    -webkit-flex-direction: column;
            flex-direction: column;
    
}


.collectionsCard .picture {
    position: relative;
    aspect-ratio: 348 / 154;
    margin: 1rem 1rem 0.5rem 1rem;
}

.collectionsCard .picture img {
    width: 100%;
    aspect-ratio: 348 / 154;
    height: 100%;
    object-fit: cover;
}

.collectionsCard .picture::after {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: 100% 100%;
    background-image: url(/static/media/frame1.3848d3c3.png);
    z-index: 2;
    pointer-events: none;
}

/* Projects */

.projectsCard {
    aspect-ratio: 1 / 2; /* to-do: make this cross browser*/
    width: 100%;
    display: -webkit-flex;
    display: flex;
    position: relative;
    /*padding: 0.5rem;*/
    -webkit-flex-direction: column;
            flex-direction: column;
    grid-row: span 2;
    
}

.projectsCard .picture {
    position: relative;
    aspect-ratio: 348 / 308;
    margin: 1rem 1rem 0.5rem 1rem;
}

.projectsCard .picture img {
    width: 100%;
    aspect-ratio: 348 / 308;
    height: 100%;
    object-fit: cover;
}

.projectsCard .picture::after {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: 100% 100%;
    background-image: url(/static/media/frame.2dcbafdc.png);
    z-index: 2;
    pointer-events: none;
}


/* Ghost */

.ghostCard {
    aspect-ratio: 2 / 1; /* to-do: make this cross browser*/
    width: 100%;
    display: -webkit-flex;
    display: flex;
    /*padding: 0.5rem;*/
    position: relative;
    outline: 5px dashed #ccc;
    outline-offset: -1rem;

    grid-column: span 2;
}

.ghostCard:after {
    content: '?';
    position: absolute;
    left: 50%;
    top: 50%;  
    -webkit-transform: translate(-50%, -50%);  
            transform: translate(-50%, -50%);
    color: #aaa;
    font-family: 'Goblin One', serif;
    font-size: 2rem;
}

blockquote {
    position: relative;
    max-width: 30em;
    margin: 0 0 2rem 0;

    background-color: #eee;
    padding: 1.125em 1.5em;
    font-size: 1.25em;
}

blockquote::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em;
    border: .75rem solid transparent;
    border-top: none;
    border-bottom-color: #eee;
}

.tags span {
    background-image: url(/static/media/postcards_02_input.b7fb9146.png);
    display: inline-block !important;
    margin-right: 0.5rem;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
}
/* Socials */
.socials {
    display: -webkit-flex;
    display: flex;
    grid-gap: 1rem;
    gap: 1rem;
    margin: 1.5rem 0 2rem;
    -webkit-flex-wrap: wrap;
            flex-wrap: wrap;
}

.socialTile {
    text-decoration: none;
    background-size: cover;
    color: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background-image: url(/static/media/green.956b3b52.png);
    padding: 0.5rem 1rem;
    outline: 3px solid transparent;
    transition: outline .1s;
    border-radius: 2rem;
}

.platformName {
    margin-left: 0.5rem;
}

.socialTile:hover {
    border: none;
    box-shadow: 0 0 0 2pt #0c8a86;
    outline: none;
    transition: .1s;
}

/* Proposals */
.proposals {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 1.5rem;
    margin: 1.5rem 0 2rem 0;
    margin-left: -0.5rem;
    width: calc(100% + 1rem);
}

.errorBox {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}
.errorBox .picture {
    position: relative;
    aspect-ratio: 348 / 348;
    margin: 1rem auto;
    max-width: 60%;
}

.errorBox .picture img {
    width: 100%;
    aspect-ratio: 348 / 348;
    height: 100%;
    object-fit: cover;
}

.errorBox .picture::after {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: 100% 100%;
    background-image: url(/static/media/frame_square.a07ad703.png);
    z-index: 2;
    pointer-events: none;
}

.errorBox h3 {
    margin-top: 2rem;
    color: #EB480D;
    font-family: 'Goblin One', serif;
    font-size: 1.5rem;
    margin-bottom: 1rem;
}

.selectButton {
  width: 100%;
  background-image: url(/static/media/beige.7d6eeb98.png);
  background-size: cover;
  text-align: center;
  border: none;
  padding: 0.6rem 2rem;
  border-radius: 0;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
}

.selectButton.selected {
  background-image: url(/static/media/pink.acce8aae.png);

}

/*
.selectButton::after {
  content: '';  
  background-color: rgba(255,255,255,0.5);
  width: 100%; height: 100%;
  top: 0; left: 0;
  position: absolute;
  transition: background-color 0.1s ease-out;
}
.selectButton.selected::after {
  background-color: rgba(255,255,255,0);
}
.selectButton.selected {
  opacity: 1;
  color: black;
}
.selectGroup:hover .selectButton.selected::after {
  background-color: rgba(255,255,255,0.5);
}

.selectGroup:hover .selectButton.selected {
  font-weight: normal;
}
.selectGroup:hover .selectButton:hover::after {
  background-color: rgba(255,255,255,0);
}
*/

