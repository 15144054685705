.resultGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    /* 4gaps adds up to 4rem */
    grid-auto-flow: dense;
    width: calc(100% - 4rem + 1rem);
    padding: 1.5rem !important;
}

@media only screen and (max-width: 1150px) {
    .resultGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 900px) {
    .resultGrid {
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 600px) {
    .resultGrid {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media only screen and (max-width: 500px) {
    .resultGrid {
        grid-template-columns: 1fr 1fr;
    }
}


.cardPadding {
    padding: 0.5rem;
}

.cardType {
    text-transform: uppercase;
/*     font-size: 14px; */
    display: block;
    margin-bottom: 0 !important;
    color: rgba(0,0,0,0.7);
}

.cardLink {
    text-decoration: none;
    display: contents;
}

.peopleCard, .collectionsCard, .projectsCard {
    cursor: pointer;
    outline-offset: -0.5rem;
    outline: 3px solid transparent;
    transition: outline 0.1s;
    overflow: hidden;
}

.peopleCard:hover, .collectionsCard:hover, .projectsCard:hover {
    outline: 3px solid #EDD6A6;
    
}

.peopleCard::before, .collectionsCard::before, .projectsCard::before {
    content: '';
    position: absolute;
    top: 0; left: 0;
    width: calc(100% - 1rem); height: calc(100% - 1rem);
    background-image: url('../assets/images/old_paper6_2_1.png'); /* 2:1 */
    background-size: 100% 100%;
    z-index: -1;
    margin: 0.5rem;
}

/* bottom inner shadows for long & overflowing collection cards */
.collectionsCard::after, .projectsCard::after, .peopleCard::after {
    content: '';
    position: absolute;
    top: 0.5rem; left: 0.5rem;
    width: calc(100% - 1rem); height: calc(100% - 1rem);
    box-shadow: 0px -40px 40px #EFE6D2 inset;
    z-index: 3;
    pointer-events: none;
}

.peopleCard .content, .collectionsCard .content, .projectsCard .content {
    margin: 1.5rem 1rem 1.5rem 0.5rem;
/*     padding: 0.5rem 15px 0.5rem 0; */
    font-size: 0.8rem;
    color: #121212;

    overflow-y: auto;
    overflow-x: hidden;
    
    flex: 1;

}
.collectionsCard .content, .projectsCard .content {
    margin: 0 0.6rem 0.5rem 0.7rem;
    padding: 0 0.5rem 1rem 0.5rem;
}

.peopleCard .content *, .collectionsCard .content *, .projectsCard .content * {
    margin-bottom: 0.5rem;
    display: block;
}

/* People */

.peopleCard {
    grid-column: span 2;
    aspect-ratio: 2 / 1; /* to-do: make this cross browser*/
    width: 100%;
    display: flex;
    /*padding: 0.5rem;*/
    position: relative;
}

.peopleCard .picture {
    position: relative;
    aspect-ratio: 308 / 348;
    margin: 1rem 0.5rem 1rem 1rem;
    z-index: 5;
}

.peopleCard .picture img {
    width: 100%;
    aspect-ratio: 308 / 348;
    height: 100%;
    object-fit: cover;
}

.peopleCard .picture::after {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: 100% 100%;
    background-image: url('../assets/images/frame.png');
    z-index: 2;
    pointer-events: none;
}

/* Collections */

.collectionsCard {
    grid-column: span 2;
    grid-row: span 2;
    aspect-ratio: 1 / 1; /* to-do: make this cross browser*/
    width: 100%;
    display: flex;
    position: relative;
    /*padding: 0.5rem;*/
    flex-direction: column;
    
}


.collectionsCard .picture {
    position: relative;
    aspect-ratio: 348 / 154;
    margin: 1rem 1rem 0.5rem 1rem;
}

.collectionsCard .picture img {
    width: 100%;
    aspect-ratio: 348 / 154;
    height: 100%;
    object-fit: cover;
}

.collectionsCard .picture::after {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: 100% 100%;
    background-image: url('../assets/images/frame1.png');
    z-index: 2;
    pointer-events: none;
}

/* Projects */

.projectsCard {
    aspect-ratio: 1 / 2; /* to-do: make this cross browser*/
    width: 100%;
    display: flex;
    position: relative;
    /*padding: 0.5rem;*/
    flex-direction: column;
    grid-row: span 2;
    
}

.projectsCard .picture {
    position: relative;
    aspect-ratio: 348 / 308;
    margin: 1rem 1rem 0.5rem 1rem;
}

.projectsCard .picture img {
    width: 100%;
    aspect-ratio: 348 / 308;
    height: 100%;
    object-fit: cover;
}

.projectsCard .picture::after {
    content: '';
    position: absolute;
    width: 100%; height: 100%;
    top: 0; left: 0;
    background-size: 100% 100%;
    background-image: url('../assets/images/frame.png');
    z-index: 2;
    pointer-events: none;
}


/* Ghost */

.ghostCard {
    aspect-ratio: 2 / 1; /* to-do: make this cross browser*/
    width: 100%;
    display: flex;
    /*padding: 0.5rem;*/
    position: relative;
    outline: 5px dashed #ccc;
    outline-offset: -1rem;

    grid-column: span 2;
}

.ghostCard:after {
    content: '?';
    position: absolute;
    left: 50%;
    top: 50%;  
    transform: translate(-50%, -50%);
    color: #aaa;
    font-family: 'Goblin One', serif;
    font-size: 2rem;
}

blockquote {
    position: relative;
    max-width: 30em;
    margin: 0 0 2rem 0;

    background-color: #eee;
    padding: 1.125em 1.5em;
    font-size: 1.25em;
}

blockquote::before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 100%;
    left: 1.5em;
    border: .75rem solid transparent;
    border-top: none;
    border-bottom-color: #eee;
}

.tags span {
    background-image: url('../assets/images/postcards_02_input.png');
    display: inline-block !important;
    margin-right: 0.5rem;
    border-radius: 2rem;
    padding: 0.5rem 1rem;
}