@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;800&family=Goblin+One&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  --container-margin: 2rem;
}

a {
  color:#eb480d;
  text-decoration: none;
}

.header {
  padding: var(--container-margin);
  display: flex;
}

.header h1 {flex: 1;}

@media only screen and (max-width: 700px) {
  .header {
    flex-direction: column;
  }
  .header h1 {
    margin-bottom: 1rem;
  }
}

h1 {
  color: #121212;
  font-family: 'Goblin One', serif; 
  margin: 0; 
  font-size: 1.7rem;
  font-weight: normal;
}

.container {
  padding: var(--container-margin);
  background-size: cover;
}

.inputGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  margin-top: 1rem;
}

h3 {
  /* color: #EB480D; */
  color: #121212;
  font-family: 'Goblin One', serif; 
  margin: 0; 
/*   font-size: 1.2rem; */
  font-weight: normal;
}

.center {
  max-width: 950px;
  margin: 0 auto;
}

.cta button {
  background-image: url(../assets/images/yellow.png);
}

button {
  background-image: url('../assets/images/postcards_02_input.png');
  background-size: cover;
  text-align: left;
  border: none;
  padding: 0.6rem 3rem;
  border-radius: 1.5rem;
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  cursor: pointer;
  position: relative;
}

button {
  transition: outline 0.1s;
}

button:hover {
  border: none;
  box-shadow: 0 0 0 2pt #d65637;
  outline: none;
  transition: .1s;
}

.topbutton:hover {
  box-shadow: 0 0 0 2pt #EDD6A6;
}