/* Socials */
.socials {
    display: flex;
    gap: 1rem;
    margin: 1.5rem 0 2rem;
    flex-wrap: wrap;
}

.socialTile {
    text-decoration: none;
    background-size: cover;
    color: #ffffff;
    display: -webkit-flex;
    display: flex;
    -webkit-align-items: center;
    align-items: center;
    background-image: url('../assets/images/green.png');
    padding: 0.5rem 1rem;
    outline: 3px solid transparent;
    transition: outline .1s;
    border-radius: 2rem;
}

.platformName {
    margin-left: 0.5rem;
}

.socialTile:hover {
    border: none;
    box-shadow: 0 0 0 2pt #0c8a86;
    outline: none;
    transition: .1s;
}

/* Proposals */
.proposals {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    margin-top: 1.5rem;
    margin: 1.5rem 0 2rem 0;
    margin-left: -0.5rem;
    width: calc(100% + 1rem);
}
